import { useCallback , useState } from "react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import styles from "../style/Login.module.css"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {bindActionCreators} from "redux";
import {actions} from "../state/index"

const Login = () => {
    const navigate = useNavigate();

    const state = useSelector(state => state.session)

    const dispatch = useDispatch()
    const {setSession, getSession} = bindActionCreators(actions, dispatch)

    const [mobileNumber, setMobileNumber] = useState("");
    const [pass, setPassword] = useState("");
    const [name, setName] = useState("");

    let loginSubmit = (event) =>{
        event.preventDefault();




        var form = new FormData();
        form.append("mobile", mobileNumber);
        form.append("pass", pass);
        form.append("name", name);

        axios
  .post(process.env.REACT_APP_URL + "/api2/register_otp.php", form)
  .then((response) => {
    if (response.data.success === "1") {
      Swal.fire({
        title: "Enter 4 digit OTP",
        input: "number",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: (otpInput) => {
          // Append the OTP input to the form
          form.append("otp", otpInput);

          // Return the axios promise to make sure Swal waits for it
          return axios
            .post(process.env.REACT_APP_URL + "/api2/register_new.php", form)
            .then((response) => {
              // Check the response for success/failure and return accordingly
              if (response.data.success === "0") {
                Swal.showValidationMessage(response.data.msg);
              }
              return response.data;
            })
            .catch((error) => {
              Swal.showValidationMessage(`Request failed: ${error}`);
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          // Check if the registration was successful
          if (result.value.success === "1") {
            setSession(result.value.token, mobileNumber);
            navigate("/home");
          } else {
            Swal.fire({
              toast: true,
              title: "Oops...",
              text: result.value.msg,
              footer: '<a href="forgot">Forgot Password ?</a>',
            });
          }
        }
      });
    } else {
      Swal.fire({
        toast: true,
        title: "Oops...",
        text: response.data.msg,
        footer: '<a href="forgot">Forgot Password ?</a>',
      });
    }
  });


  

      

        if (state){
            return <Navigate to={"/home"} />
        }
    }

    const onForgotClick = useCallback(() => {
        navigate("/forgot");
    }, [navigate]);

    return (
        <div className="RootView">
            <div className="RootWidth authBack">
                <div className={styles.loginDiv}>
                    <img className="logoIcon" alt="" src="../images/tinedlogo.png" style={{width:"150px"}} />

                    <form
                        className={styles.frameForm}
                        method="post"
                        onSubmit={loginSubmit}
                    >
                        <div className="pageHeadings"
                            style={{fontSize:"18px", marginBottom:"12px"}}
                        >Register Your Account</div>

                        <div className="inputBlock">
                            <img src="../images/profile_icon.png" />
                            <input
                                name="name"
                                type="text"
                                value={name}
                                onChange={(e)=>{setName(e.target.value)}}
                                placeholder="Enter Name"
                            />
                        </div>

                        
                        <div className="inputBlock">
                            <img src="../images/login_icon.png" />
                            <input
                                name="mobile"
                                type="number"
                                value={mobileNumber}
                                onChange={(e)=>{setMobileNumber(e.target.value)}}
                                placeholder="Enter mobile number"
                            />
                        </div>

                        
                        <div className="inputBlock">
                            <img src="../images/padlock.png" />
                            <input 
                                type="password"
                                name="passwword"
                                value={pass}
                                placeholder="Enter password"
                                onChange={(e)=>{setPassword(e.target.value)}}
                            />
                        </div>

                        

                        


                        <button className="button">
                            <b>REGISTER</b>
                        </button>

                        <button className="button">
                            <Link to="/login">
                                <b>ALREADY HAVE ACCOUNT</b>
                            </Link>
                        </button>

                    </form>

                </div>
            </div>
        </div>
    );
};

export default Login;
