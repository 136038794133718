import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            wallet: "",
            history:[]
        }
    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/transaction_history.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        wallet:response.data.wallet,
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>My Wallet</span>
                                </div>

                                <div>
                                    <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                        <img src="../images/add_wallet_screen.png" />
                                    </Link>
                                </div>

                            </div>

                            <div className="upi_apps">
                                <div className="button" style={{width:"100%", marginLeft:"12px", marginRight:"12px"}}>
                                    <span className="heading" style={{color:"white", borderBottom:"solid 1px #fff", fontSize:"35px", fontWeight:"600"}}>₹ {this.state.wallet}</span>
                                    <span className="subheading" style={{color:"white", fontSize:"15px", fontWeight:"600"}}>Wallet Amount</span>
                                </div>

                            </div>

                            <div className="row homemenu" style={{padding:"20px"}}>
                                        <div className="col-6">
                                            <Link to={"/deposit?mobile="+localStorage.getItem("mobile")}>
                                                <div className="homediv">
                                                    <img src="../images/wallet_add.png" />
                                                    <span>Add Money</span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <Link to={"/withdraw"}>
                                                <div className="homediv">
                                                    <img src="../images/wallet_deduct.png" />
                                                    <span>Withdraw</span>
                                                </div>
                                            </Link>
                                        </div>
                                      
                                       
                                    </div>

                           
                            <div style={{padding:"15px"}}>
                                <span className="pageTitle">Transactions</span>

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block" key={result.sn}>
                                            <div>
                                                <span className="">{result.remark}</span>
                                                <span>{result.date}</span>
                                            </div>
                                            <span>{result.amount}</span>
                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
