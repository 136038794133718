import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import { type } from "@testing-library/user-event/dist/type";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            wallet: "",
            history:[]
        }
    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));


        axios
            .post(process.env.REACT_APP_URL+"/api2/withdraw_reqs.php", form,
            )
            .then((response) => {
                console.log(response.data)
                if(response.status === 200){
                    this.setState({
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {



        return (<>
                <div className="RootView">
                    <div className="RootWidth" style={{background:"#eee"}}>

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Withdraw Requests</span>
                                </div>


                            </div>

                            <div style={{padding:"15px"}}>

                                

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        
                                        <div className="gameBox">

                                        
                                        <div className="histMia">
                                            <div className="gameBoxMina">
                                                <span  className="gameboxmain">{result.date}</span>
                                                <span  className="gameboxmain divgame">₹{result.amount}</span>
                                            </div>

                                            {result.status === "Completed" && (
                                                <div className="histMiaSub">
                                                    <img src="../images/check_mark.png" />
                                                    <span style={{color:"05bf05"}}>Completed</span>
                                                </div>
                                            )}

                                            {result.status === "Pending" && (
                                                <div className="histMiaSub">
                                                    <img src="../images/pending.png" />
                                                    <span style={{color:"#d5aa43"}}>Pending</span>
                                                </div>
                                            )}

                                            {result.status === "Rejected" && (
                                                <div className="histMiaSub">
                                                    <img src="../images/close_icon.png" />
                                                    <span style={{color:"red"}}>Rejected</span>
                                                </div>
                                            )}
                                            
                                            

                                        </div>
                                        


                                        <div className="transaction_block" style={{marginBottom:"10px"}} key={result.sn}>
                                        <div style={{textAlign:"left", marginLeft:"5px"}}>
                                                <span className="gameboxlable2">Fund ID</span>
                                                <span>{result.fund_id}</span>
                                            </div>
                                            <div style={{textAlign:"left", marginLeft:"5px"}}>
                                                <span className="gameboxlable2">Request Type</span>
                                                <span>Debit</span>
                                            </div>
                                       
                                        </div>

                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>

                           
                </div>


            </>
        );
    }

}

export default Wallet;
