import {useLocation, useNavigate} from "react-router-dom";


const Splash = () => {
    const navigate = useNavigate()
    const location = useLocation()

    if (location.state){
        localStorage.clear()
        navigate("/login")
    }

    if (localStorage.getItem("token") != null){
        let s = setInterval(() => {
                navigate("/home");
                clearInterval(s)
            },1000
        );
    } else {
        let s = setInterval(() => {
                navigate("/login");
                clearInterval(s)
            },1000
        );
    }

    return (
        <div className="RootView">
            <div className="RootWidth">
                <div className="SplashDiv">
                    <img className="logoIcon" alt="" src="../images/tinedlogo.png" />
                </div>
            </div>
        </div>
    );
};

export default Splash;
