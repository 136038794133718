import { useCallback , useState } from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import styles from "../style/Login.module.css"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import {bindActionCreators} from "redux";
import {actions} from "../state/index"

const Login = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const state = useSelector(state => state.session)
    const dispatch = useDispatch()
    const {setSession, getSession} = bindActionCreators(actions, dispatch)

    const [mobileNumber, setMobileNumber] = useState("");
    const [pass, setPassword] = useState("");

    const baseurl = process.env.REACT_APP_URL;

    let loginSubmit = (event) =>{
        event.preventDefault();

        var form = new FormData();
        form.append("mobile", mobileNumber);
        form.append("pass", pass);

        axios
            .post(`${baseurl}/api2/login.php`,form)
            .then((response) => {
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg,
                        footer: '<link to="forgot">Forgot Password ?</a>'
                    })

                } else {
                    setSession(response.data.token, mobileNumber)
                    navigate("/home")
                }
            })

        if (state){
            return <Navigate to={"/home"} />
        }
    }

    let forgot2 = (event) =>{
        event.preventDefault();

        var form = new FormData();
        form.append("mobile", mobileNumber);

    
            axios
                .post(`${baseurl}/api2/login_check.php`,form)
                .then((response) => {
                    window.open("https://wa.me/+91"+response.data.whatsapp);
                   
                })
       
    }


    const onForgotClick = useCallback(() => {
        navigate("/forgot");
    }, [navigate]);

    return (
        <div className="RootView">
            <div className="RootWidth authBack">
                <div className={styles.loginDiv}>

                    <div className="loginTopBlock">
                        <div></div>
                        <span>ENTER YOUR MOBILE<br/>NUMBER</span>
                    </div>

                    <img className="logoIcon" alt="" src="../images/login_set.jpg" />

                    <form
                        className={styles.frameForm}
                        method="post"
                        onSubmit={loginSubmit}
                    >
                        
                        <div className="inputBlock">
                            <img src="../images/login_icon.png" />
                            <input
                                name="mobile"
                                type="number"
                                value={mobileNumber}
                                onChange={(e)=>{setMobileNumber(e.target.value)}}
                                placeholder="Enter your mobile number"
                            />
                        </div>

                        <div className="inputBlock">
                            <img src="../images/padlock.png" />
                            <input type="password"
                               name="passwword"
                               value={pass}
                               placeholder="Enter your password"
                               onChange={(e)=>{setPassword(e.target.value)}}
                        />
                        </div>
                        

                        <div className={styles.frameDiv} >
                            <div className={styles.forgotDiv} onClick={onForgotClick}>Forgot Password ?</div>
                        </div>

                        <button className="button" style={{marginTop:"10px"}}>
                            <b>LOGIN</b>
                        </button>

                        <button className="button">
                            <Link to="/signup">
                                <b>REGISTER</b>
                            </Link>
                        </button>

                        
                        <button className="button" onClick={forgot2} style={{background:"green", marginTop:"20px"}}>
                          <img src="../images/whatsapp (14)-min.png" style={{height:"25px", marginRight:"10px"}} />
                                <b>Contact us on Whatsapp</b>
                          
                        </button>
                        
                    </form>

                </div>
            </div>
        </div>
    );
};

export default Login;
