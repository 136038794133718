import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";
import PullToRefresh from 'react-simple-pull-to-refresh';


class Home extends React.Component {

    constructor() {
        super();
        this.state = {
            homeMsg: "Welcome to demo matka play and win",
            number: "Loading",
            wallet: "0",
            results:[],
            width_b:"0%",
            width:"0%",
            nav_display:"none",
            transfer_point:"none",
            whatsapp:"",
            whatsappNum:"",
            pupup:"",
            showPopup:true,
            name:"",
            call:""
        }


    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/home.php", form,
            )
            .then((response) => {
                if(response.status === 200){

                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }


                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        transfer_point: response.data.transfer_points_status,
                        results:response.data.result,
                        wallet: response.data.wallet,
                        homeMsg:response.data.tet,
                        name:response.data.name,
                        popup:response.data.popup_image,
                        whatsapp:"https://wa.me/+91"+response.data.whatsapp,
                        whatsappNum:response.data.whatsapp,
                        call:"tel://+91"+response.data.whatsapp
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {

        
        let handleRefresh = ()=>{
            window.location.reload();
        }

        let switchNav = () =>{
            if (this.state.width === "0%"){
                this.setState({
                    width:"100%",
                    width_b:"67%",
                    nav_display:"flex"
                })
            } else {
                this.setState({
                    width:"0%",
                    width_b:"0%",
                    nav_display:"none"
                })
            }
        }

        let marketCloseError = ()=>{
            Swal.fire({
                toast: true,
                title: 'Oops...',
                text: "Market Already Closed, Please come again tomorrow"
            })
        }

        let closePopup = ()=>{
            this.setState({
                showPopup:false
            })
        }

        // if (this.state.showPopup){
        //     document.body.style.overflow = 'hidden';
        // } else {
        //     document.body.style.overflow = 'unset';
        // }
        //
        // window.addEventListener('popstate', (event) => {
        //     if (event.state) {
        //         if (this.state.showPopup){
        //             this.setState({
        //                 showPopup:false
        //             })
        //         } else {
        //             window.history.go(-1)
        //         }
        //     }
        // }, false);

        return (<>
            <div className="RootView">
                <div className="RootWidth" style={{background:"#eee"}}>

                

                <PullToRefresh onRefresh={handleRefresh}>
                    <div className="loginDiv" style={{paddingBottom:"15%"}}>



                    <div className="bottomNav">
                            <div>
                                <img src="../images/my_bid.png" />
                                <span>My Bids</span>
                            </div>

                            <Link to={"/passbook"}>
                            <div>
                                <img src="../images/passbook.png" />
                                <span>Passbook</span>
                            </div>
                            </Link>
                            <Link to={"/home"}>
                            <div className="home">
                                <img src="../images/home_flat.png" />
                            </div>
                            
                            </Link>
                           
                            <Link to={"/funds"}>
                            <div>
                                <img src="../images/funds.png" />
                                <span>Funds</span>
                            </div>
                            </Link>
                            <a href={ this.state.whatsapp} target="_BLANK">
                            <div>
                                <img src="../images/customer_support.png" />
                                <span>Support</span>
                            </div>
                            </a>
                        </div>

                        <div className="toolbar">

                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Funds</span>
                                </div>
                            <Link to={"/"}>
                                <div>
                                    <img className="wallet-img" src="../images/wallet_figma.png"/>
                                    <span className="wallet-text">{localStorage.getItem('wallet')}</span>
                                </div>
                            </Link>


                        </div>
                        

                   

                        <div style={{padding:"5px"}}>

                           

                        <Link style={{width:"100%"}} to={"/gameHistory?type=main"}>
                            <div className="market_box fundblock">
                             
                                <div  className="main">

                                    <div style={{display:"flex", alignItems:"center"}}>
                                        
                                    <img style={{height:"35px", marginRight: "14px"}}  src="../images/auction.png" />
                                        <div className="inner_market_box" style={{textAlign:"center"}}>
                                            <span className="market_name" style={{fontWeight:"600"}}>Bid History</span>
                                            <span className="market_time subline" style={{marginRight:"15px"}}>You can view your market bid history</span>
                                        </div>
                                    </div>
                              
                                
                                <div className="inner_market_box" style={{alignItems:"center"}}>
                                  
                                <img style={{width:"30px"}}  src="../images/black_arrow.png" />
                                  
                                </div>
                               
                                </div>
                            </div>
                            </Link>

                            
                            <Link style={{width:"100%"}}  to={"/gameHistory?type=starline"} state={{type:"starline"}}>
                            <div className="market_box fundblock">
                             
                                <div  className="main">

                                    <div style={{display:"flex", alignItems:"center"}}>
                                        
                                    <img style={{height:"35px", marginRight: "14px"}}  src="../images/scroll.png" />
                                        <div className="inner_market_box" style={{textAlign:"center"}}>
                                            <span className="market_name" style={{fontWeight:"600"}}>Main Starline Bid History</span>
                                            <span className="market_time subline" style={{marginRight:"15px"}}>You can view your starline bid history</span>
                                        </div>
                                    </div>
                              
                                
                                <div className="inner_market_box" style={{alignItems:"center"}}>
                                  
                                <img style={{width:"30px"}}  src="../images/black_arrow.png" />
                                  
                                </div>
                               
                                </div>
                            </div>
                            </Link>
                            
                            <Link style={{width:"100%"}}  to={"/gameHistory?type=delhi"} state={{type:"delhi"}}>
                            <div className="market_box fundblock">
                             
                                <div  className="main">

                                    <div style={{display:"flex", alignItems:"center"}}>
                                        
                                    <img style={{height:"35px", marginRight: "14px"}}  src="../images/gali_bid.png" />
                                        <div className="inner_market_box" style={{textAlign:"center"}}>
                                            <span className="market_name" style={{fontWeight:"600"}}>Main Jackpot Bid History</span>
                                            <span className="market_time subline" style={{marginRight:"15px"}}>You can view your jackpot bid history</span>
                                        </div>
                                    </div>
                              
                                
                                <div className="inner_market_box" style={{alignItems:"center"}}>
                                  
                                <img style={{width:"30px"}}  src="../images/black_arrow.png" />
                                  
                                </div>
                               
                                </div>
                            </div>

                            </Link>
                        </div>

                    </div>

                    </PullToRefresh>

                </div>
            </div>


        </>)
    }

}


export default Home;
