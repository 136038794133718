import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            wallet: "",
            history:[],
        }
    }

    componentDidMount() {

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));


        axios
            .post(process.env.REACT_APP_URL+"api2/charts.php", form,
            )
            .then((response) => {
                console.log(response)
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    this.setState({
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {

        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Charts</span>
                                </div>


                            </div>



                            <div className="grid2x2">

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block" key={result.sn}>

                                                <div className="chartButton">
                                                    <Link style={{width:"100%"}}  to={"/chart"} state={{url:process.env.REACT_APP_URL+"/api/chart2/getChart.php?market="+result.market}}>
                                                        <span >{result.market}</span>
                                                    </Link>
                                                </div>

                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
