import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class GameRates extends React.Component {

    constructor() {
        super();
        this.state = {
            single: "-",
            double: "-",
            singlepatti: "0",
            doublepatti:"0",
            triplepatti:"0",
            halfsangam:"0",
            fullsangam:"0",
            star_single:"0",
            star_sp:"0",
            star_dp:"0",
            star_tp:"0",
            delhi_single:"0",
            delhi_jodi:"0",
        }


    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/rate.php", form,
            )
            .then((response) => {
                console.log(response)
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    this.setState({
                        single:response.data.single,
                        double:response.data.jodi,
                        singlepatti: response.data.singlepatti,
                        doublepatti:response.data.doublepatti,
                        triplepatti:response.data.triplepatti,
                        halfsangam:response.data.halfsangam,
                        fullsangam:response.data.fullsangam,
                        star_single:response.data.star.single,
                        star_sp:response.data.star.singlepatti,
                        star_dp:response.data.star.doublepatti,
                        star_tp:response.data.star.triplepatti,
                        delhi_single:response.data.delhi.single,
                        delhi_jodi:response.data.delhi.jodi,
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {


        return (<>
                <div className="RootView">
                    <div className="RootWidth" style={{background:"#eee"}}>

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img src="../images/back.png" onClick={()=>{window.history.go(-1)}} style={{padding: "5px"}}/>
                                    <span>Game Rates</span>
                                </div>


                            </div>

                            <div style={{padding:"5px", background:"#eee"}}>

                                <div className="listview">
                                    <button className="button forate">
                                        Game Win Ratio for all Bids
                                    </button>

                                    <span className="ratenox">Single - {this.state.single}</span>
                                    <span className="ratenox">Jodi - {this.state.double}</span>
                                    <span className="ratenox">Single Pana - {this.state.singlepatti}</span>
                                    <span className="ratenox">Double Pana - {this.state.doublepatti}</span>
                                    <span className="ratenox">Triple Pana - {this.state.triplepatti}</span>
                                    <span className="ratenox">Half Sangam - {this.state.halfsangam}</span>
                                    <span className="ratenox">Full Sangam - {this.state.fullsangam}</span>

                                    
                                    <button className="button forate">
                                        Main Starline Game Win Ratio
                                    </button>

                                    <span className="ratenox">Single - {this.state.star_single}</span>
                                    <span className="ratenox">Single Pana - {this.state.star_sp}</span>
                                    <span className="ratenox">Double Pana - {this.state.star_dp}</span>
                                    <span className="ratenox">Triple Pana - {this.state.star_tp}</span>



                                    <button className="button forate">
                                        Main Jackpot Win Ratio
                                    </button>

                                    <span className="ratenox">Single - {this.state.delhi_single}</span>
                                    <span className="ratenox">Jodi - {this.state.delhi_jodi}</span>


                                </div>

                        
                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default GameRates;
