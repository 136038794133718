import { useState, useEffect  } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Swal from "sweetalert2";
import {Link} from "react-router-dom";
import axios from "axios";

const Home = () => {

    const navigate = useNavigate();
    const [bids, setbids] = useState([]);
    const [suggestions, setsuggestions] = useState([]);

    const [openSession, setopenSession] = useState("activeButton");
    const [closeSession, setcloseSession] = useState("inactiveButton");
    const [display, setdisplay] = useState("");
    const [session, setSession] = useState("OPEN"); // Initialize with a default value
    const [gameTypeSP, setgameTypeSP] = useState(""); // Initialize with a default value
    const [gameTypeDP, setgameTypeDP] = useState(""); // Initialize with a default value
    const [gameTypeTP, setgameTypeTP] = useState(""); // Initialize with a default value

    
    const [totalAmount, settotalAmount] = useState("");
    
    const [totalBids, settotalBids] = useState(0);

    const [showLoading, setshowLoading] = useState("none");
    const [dialogShow, setdialogShow] = useState("none");
    const [dialogShowSuccess, setdialogShowSuccess] = useState("none");

    let showDialog = ()=>{
        setdialogShow("flex")
    }
    
    let hideDialog = ()=>{
        setdialogShow("none")
    }

    
      
    
      const sps = [
        '128', '137', '146', '236', '245', '290', '380', '470', '489', '560',
        '678', '579', '589', '129', '138', '147', '156', '237', '246', '345',
        '390', '480', '570', '679', '120', '139', '148', '157', '238', '247',
        '256', '346', '490', '580', '670', '689', '130', '149', '158', '167',
        '239', '248', '257', '347', '356', '590', '680', '789', '140', '159',
        '168', '230', '249', '258', '267', '348', '357', '456', '690', '780',
        '123', '150', '169', '178', '240', '259', '268', '349', '358', '457',
        '367', '790', '124', '160', '179', '250', '269', '278', '340', '359',
        '368', '458', '467', '890', '125', '134', '170', '189', '260', '279',
        '350', '369', '378', '459', '567', '468', '126', '135', '180', '234',
        '270', '289', '360', '379', '450', '469', '478', '568', '127', '136',
        '145', '190', '235', '280', '370', '479', '460', '569', '389', '578',
      ];

      const dps = [
        '100', '119', '155', '227', '335', '344', '399', '588', '669', '200',
        '110', '228', '255', '336', '499', '660', '688', '778', '300', '166',
        '229', '337', '355', '445', '599', '779', '788', '400', '112', '220',
        '266', '338', '446', '455', '699', '770', '500', '113', '122', '177',
        '339', '366', '447', '799', '889', '600', '114', '277', '330', '448',
        '466', '556', '880', '899', '700', '115', '133', '188', '223', '377',
        '449', '557', '566', '800', '116', '224', '233', '288', '440', '477',
        '558', '990', '900', '117', '144', '199', '225', '388', '559', '577',
        '667', '550', '668', '244', '299', '226', '488', '677', '118', '334',
      ];
      

      const tps = ['000', '111', '222', '333', '444', '555', '666', '777', '888', '999'];

      

    let timing = ""

    let data = useLocation();

    if (data.state == null){
        window.location.href = "/";
    }

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    let game_name = data.state[0].game;
    if (data.state[0].timing !== undefined) {
        timing = data.state[0].timing
    }



   let numbers = [
    "127", "136", "145", "190", "235", "280", "370", "389", "460", "479", "569", "578", "128", "137", "146", 
    "236", "245", "290", "380", "470", "489", "560", "579", "678", "129", "138", "147", "156", "237", "246", 
    "345", "390", "480", "570", "589", "679", "120", "139", "148", "157", "238", "247", "256", "346", "490", 
    "580", "670", "689", "130", "149", "158", "167", "239", "248", "257", "347", "356", "590", "680", "789", 
    "140", "159", "168", "230", "249", "258", "267", "348", "357", "456", "690", "780", "123", "150", "169", 
    "178", "240", "259", "268", "349", "358", "367", "457", "790", "124", "160", "278", "179", "250", "269", 
    "340", "359", "368", "458", "467", "890", "125", "134", "170", "189", "260", "279", "350", "369", "468", 
    "378", "459", "567","126", "135","180","234","270","289","360","379","450","469","478","568"
];


    let getSuggest = (val)=>{
        if(val.length < 2){
            setbid(val)
        }
    }
    let getSuggest2 = (val)=>{
        if(val.length  < 2){
            setbid2(val)
        }
    }
    let getSuggest3 = (val)=>{
        if(val.length  < 2){
            setbid3(val)
        }
    }

    let changeSession = (e)=>{
        console.log(e);
        if (e === "OPEN" && is_open_status === "1"){
            setSession("OPEN")
        } else if (e === "CLOSE"){
            setSession("CLOSE")
        }
    }

    useEffect(() => {
        // Check the condition and update the session state accordingly
        if(game_name !== "jodi"){
            if (is_open_status === "0") {
            setSession("CLOSE");
            } else {
            setSession("OPEN");
            }
        } else {
            setSession("");
        }
      }, [is_open_status]); // The effect will re-run whenever is_open_status changes



    const getTotalAmount = (bids) => {
        let totalAmount = 0;
        bids.forEach(bid => {
          totalAmount += parseInt(bid.amount);
        });
        return totalAmount;
      };

    
      
      let addBid = () => {
        if (parseInt(amount) >= localStorage.getItem('min_bet')) {

            let bidThing = "";
            if(bid != ""){
                bidThing += bid;
            } else {
                bidThing += "*"
            }
            if(bid2 != ""){
                bidThing += bid2;
            } else {
                bidThing += "*"
            }
            if(bid3 != ""){
                bidThing += bid3;
            } else {
                bidThing += "*"
            }


            if(bidThing != "***"){

                let firstDigit = bidThing.substring(0, 1);
                let SecondDigit = bidThing.substring(1, 2);
                let thirdDigit = bidThing.substring(2, 3);
                
                if(gameTypeSP == "SP"){
                    
                    for (let a = 0; a < sps.length; a++){
                        

                        if(firstDigit != "*" && sps[a].substring(0,1) != firstDigit){
                            continue;
                        }
                        if(SecondDigit != "*" && sps[a].substring(1,2) != SecondDigit){
                            console.log("sdd");
                            continue;
                        }
                        if(thirdDigit != "*" && sps[a].substring(2,3) != thirdDigit){
                            continue;
                        }
                        let bidObj = {amount: amount, number:sps[a], session: session}
                        setbids(prevBids => {
                            const updatedBids = [...prevBids, bidObj];
                            const totalAmount = getTotalAmount(updatedBids);
                            settotalAmount(totalAmount);
                            settotalBids(updatedBids.length);
                            return updatedBids;
                          });
                    } 
                }
                
                  
                if(gameTypeDP == "DP"){
                    
                    for (let a = 0; a < dps.length; a++){
                        
                        if(firstDigit != "*" && dps[a].substring(0,1) != firstDigit){
                            continue;
                        }
                        if(SecondDigit != "*" && dps[a].substring(1,2) != SecondDigit){
                            continue;
                        }
                        if(thirdDigit != "*" && dps[a].substring(2,3) != thirdDigit){
                            continue;
                        }
                        let bidObj = {amount: amount, number:dps[a], session: session}
                        setbids(prevBids => {
                            const updatedBids = [...prevBids, bidObj];
                            const totalAmount = getTotalAmount(updatedBids);
                            settotalAmount(totalAmount);
                            settotalBids(updatedBids.length);
                            return updatedBids;
                          });
                    } 
                }
                     
                  
                if(gameTypeTP == "TP"){
                    
                    for (let a = 0; a < tps.length; a++){
                        
                        if(firstDigit != "*" && tps[a].substring(0,1) != firstDigit){
                            continue;
                        }
                        if(SecondDigit != "*" && tps[a].substring(1,2) != SecondDigit){
                            continue;
                        }
                        if(thirdDigit != "*" && tps[a].substring(2,3) != thirdDigit){
                            continue;
                        }
                        let bidObj = {amount: amount, number:tps[a], session: session}
                        setbids(prevBids => {
                            const updatedBids = [...prevBids, bidObj];
                            const totalAmount = getTotalAmount(updatedBids);
                            settotalAmount(totalAmount);
                            settotalBids(updatedBids.length);
                            return updatedBids;
                          });
                    } 
                }

                // if(setgameTypeDP == "DP"){
                //     dp = true
                // } else {
                //     dp = false
                // }
                // if(setgameTypeTP == "DP"){
                //     tp = true
                // } else {
                //     tp = false
                // }



                
            }
        
         
        } else if (parseInt(amount) < 10) {
          Swal.fire({
            toast: true,
            title: 'Oops...',
            text: "Bid Amount must be above "+localStorage.getItem("min_bet")+" INR"
          });
        } else if (!numbers.includes(parseInt(bid)) && !numbers.includes(bid)) {
          Swal.fire({
            toast: true,
            title: 'Oops...',
            text: "Enter valid bid number"
          });
        }
      };
      
      let removePeople = (e) => {
        setbids(prevBids => {
          const updatedBids = prevBids.filter(person => person !== e);
          const totalAmount = getTotalAmount(updatedBids);
          settotalAmount(totalAmount);
          settotalBids(updatedBids.length);
          return updatedBids;
        });
      };

    const [amount, setamount] = useState("");
    const [bid, setbid] = useState("");
    const [bid2, setbid2] = useState("");
    const [bid3, setbid3] = useState("");


    let setbidss = (bal)=>{
        setbid(bal)
        setsuggestions([])
    }

    let loginSubmit = () =>{

        setshowLoading("block");
        let numberString,amountString,typesString

        let numberArray = []
        let amountArray = []
        let typesArray = []

        bids.map((result) => {
            numberArray.push(result.number)
            amountArray.push(result.amount)
            typesArray.push(result.session)
        })

        numberString = numberArray.join(",")
        amountString = amountArray.join(",")
        typesString = typesArray.join(",")

        var form = new FormData();

        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        form.append("bazar", marketName);
        form.append("number", numberString);
        form.append("amount", amountString);
        form.append("types", typesString);
        form.append("game", game_name);
        if (timing !== "") {
            form.append("timing", timing);
        }
        axios
            .post(process.env.REACT_APP_URL+`/api2/bet.php`,form)
            .then((response) => {
                if (response.data.nosession){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Session Expired"
                    }).then((e)=>{
                        localStorage.clear()
                        window.location.href = "/login";
                    })
                }
                if(response.data.success === "0"){
                    setshowLoading("none");
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg
                    })
                } else {
                    setshowLoading("none");
                    setdialogShow("none")
                    setdialogShowSuccess("flex")
                }
            })
    }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                <div style={{display:showLoading}}>
                        <div class="loader-parent">
                        <div class="loader-block">
                        <div class="loader"></div> 
                        </div>
                        </div>
                    </div>


                    <div className="loginDiv">

                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span style={{ textTransform: "uppercase"}}>{marketName}, Choice Pana</span>
                            </div>
                            <Link to={"/wallet"}>
                                <div>
                                    <img className="wallet-img" src="../images/wallet_figma.png"/>
                                    <span className="wallet-text">{localStorage.getItem("wallet")}</span>
                                </div>
                            </Link>
                        </div>


                        <div className="main-game-screen">

                        { game_name !== "jodi" ?
                        <div className="game-play-box" onChange={(e) => changeSession(e)} style={{marginBottom: "10px"}}>
                                <span>
                                    Select Game Type :
                                </span>
                                <select>
                                { is_open_status !== "0" ?
                                    <option value="OPEN">OPEN</option>  : ""}
                                    <option value="CLOSE">CLOSE</option>
                                </select>
                            </div> : ""}

                            <div className="radioGroup">
                                <div>
                                    <input
                                    type="checkbox"
                                    id="sp"
                                    name="sp"
                                    value="SP"
                                    checked={gameTypeSP === "SP"}
                                    onChange={(e) => setgameTypeSP(e.target.checked ? "SP" : "")}
                                    />
                                    <label htmlFor="sp">SP</label>
                                </div>
                                <div>
                                    <input
                                    type="checkbox"
                                    id="dp"
                                    name="dp"
                                    value="DP"
                                    checked={gameTypeDP === "DP"}
                                    onChange={(e) => setgameTypeDP(e.target.checked ? "DP" : "")}
                                    />
                                    <label htmlFor="dp">DP</label>
                                </div>
                                <div>
                                    <input
                                    type="checkbox"
                                    id="tp"
                                    name="dp"
                                    value="TP"
                                    checked={gameTypeTP === "TP"}
                                    onChange={(e) => setgameTypeTP(e.target.checked ? "TP" : "")}
                                    />
                                    <label htmlFor="tp">TP</label>
                                </div>
                                </div>

                                <div className="game-play-box" >
                                <div style={{width:"30%"}}>
                                    <input
                                        className="edittext basicAutoComplete"
                                        data-url="../single.json"
                                        name="mobile"
                                        style={{marginBottom:"0px", width:"100%"}}
                                        type="number"
                                        autoComplete="off"
                                        value={bid}
                                        onChange={(e)=>{getSuggest(e.target.value)}}
                                        placeholder="LEFT"
                                    />
                                </div>
                                <div style={{width:"30%"}}>
                                <input
                                        className="edittext basicAutoComplete"
                                        data-url="../single.json"
                                        name="mobile"
                                        style={{marginBottom:"0px", width:"100% !important"}}
                                        type="number"
                                        autoComplete="off"
                                        value={bid2}
                                        onChange={(e)=>{getSuggest2(e.target.value)}}
                                        placeholder="CENTER"
                                    />
                                </div>
                                <div style={{width:"30%"}}>
                                <input
                                        className="edittext basicAutoComplete"
                                        data-url="../single.json"
                                        name="mobile"
                                        style={{marginBottom:"0px", width:"100%"}}
                                        type="number"
                                        autoComplete="off"
                                        value={bid3}
                                        onChange={(e)=>{getSuggest3(e.target.value)}}
                                        placeholder="RIGHT"
                                    />
                                </div>
                                </div>

                          


                            <div className="game-play-box">
                                <span>
                                    Enter Points :
                                </span>
                                <div>
                                    <input
                                        className="edittext"
                                        name="mobile"
                                        type="number"
                                        style={{marginTop:"10px"}}
                                        value={amount}
                                        onChange={(e)=>{setamount(e.target.value)}}
                                        placeholder="Enter Points"
                                    />

                                </div>
                            </div>

                            <div className="game-play-add">
                                <button className="button" onClick={addBid}>
                                    ADD BID
                                </button>
                            </div>

                            <div className="bet-list bottom-border">
                                    <span>
                                        Digit
                                    </span>
                                    <span>
                                       Points
                                    </span>
                                    <span>
                                        Game type
                                    </span>
                                </div>

                            { bids.map((result) => { return (
                                <div className="bet-list bet-shadow">
                                    <span>
                                        {result.number}
                                    </span>
                                    <span>
                                       {result.amount}
                                    </span>
                                    <span>
                                        {result.session} <img style={{marginLeft:"10px"}} onClick={()=>{removePeople(result)}} src="../images/ic_delete.png" />
                                    </span>
                                </div>
                            )})}


                        </div>
                    </div>

                </div>
                { totalBids !== 0 ?
                <div className="game-play-bottom">

                    <div className="game-play-bottom-first-div">
                        <div className="list-div">
                            <span>Bid</span>
                            <span>{totalBids}</span>
                        </div>
                        
                        <div className="list-div">
                            <span>Total</span>
                            <span>{totalAmount}</span>
                        </div>
                    </div>
                    
                    <div>
                    <button className="button" onClick={showDialog}>
                                Submit
                            </button>
                    </div>
                </div>
                 : ""}


<div className="review-bets-dialog" style={{display:dialogShowSuccess}}>
      <div className="dialog-content">
      
        <div className="bet-details" style={{flexDirection:"column", alignItems:"center"}}>

            <img src="../images/success_green.png" style={{height:"75px", marginBottom:"14px"}} />
            <span style={{color:"green"}}>Your Bids Placed Successfully</span>
         
         
        </div>
       
        <div className="dialog-actions">
          <button className="submit-button" onClick={()=>{window.history.go(-1)}}>OK</button>
        </div>
      </div>
    </div>

<div className="review-bets-dialog" style={{display:dialogShow}}>
      <div className="dialog-content">
        <div className="dialog-header">
          <h2>Review Bets</h2>
        </div>
        <div className="bets-table">
          <div className="table-header">
            <div>Digit</div>
            <div>Points</div>
            <div>Type</div>
          </div>
          { bids.map((result) => { return (
            <div className="bet-list bet-shadow">
                <span>
                    {result.number}
                </span>
                <span>
                    {result.amount}
                </span>
                <span>
                    {result.session}
                </span>
            </div>
        )})}
        </div>
        <div className="bet-details">
          <div>
            <span>Total Bids</span>
            <strong>{totalBids}</strong>
          </div>
          <div>
            <span>Total Bid Points</span>
            <strong>{totalAmount}</strong>
          </div>
        </div>
        <div className="balance-details">
          <div>
            <span>Point Balance Before Game Play</span>
            <strong>{localStorage.getItem("wallet")}</strong>
          </div>
          <div>
            <span>Point Balance After Game Play</span>
            <strong>{localStorage.getItem("wallet") - totalAmount}</strong>
          </div>
        </div>
        <div className="note">
          <p>Note: Bid Once Played Can Not Be Cancelled</p>
        </div>
        <div className="dialog-actions">
          <button className="cancel-button" onClick={hideDialog}>Cancel</button>
          <button className="submit-button" onClick={loginSubmit}>Submit</button>
        </div>
      </div>
    </div>


            </div>

        </>
    );
};


export default Home;
