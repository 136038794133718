import { useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import Swal from "sweetalert2";
import axios from "axios";

const Home = () => {

    const navigate = useNavigate();
    const [openPana, setopenPana] = useState([]);
    const [closePana, setclosePana] = useState([]);
    const [types, settype] = useState("");
    const [bids, setbids] = useState([]);

    const [display, setdisplay] = useState("");


    let data = useLocation();

    let marketName = data.state[0].market;
    let is_open_status = data.state[0].is_open;
    let game_name = data.state[0].game;


   let numbers = [
    "127", "136", "145", "190", "235", "280", "370", "389", "460", "479", "569", "578", "128", "137", "146", 
    "236", "245", "290", "380", "470", "489", "560", "579", "678", "129", "138", "147", "156", "237", "246", 
    "345", "390", "480", "570", "589", "679", "120", "139", "148", "157", "238", "247", "256", "346", "490", 
    "580", "670", "689", "130", "149", "158", "167", "239", "248", "257", "347", "356", "590", "680", "789", 
    "140", "159", "168", "230", "249", "258", "267", "348", "357", "456", "690", "780", "123", "150", "169", 
    "178", "240", "259", "268", "349", "358", "367", "457", "790", "124", "160", "278", "179", "250", "269", 
    "340", "359", "368", "458", "467", "890", "125", "134", "170", "189", "260", "279", "350", "369", "468", 
    "378", "459", "567","126", "135","180","234","270","289","360","379","450","469","478","568"
];

    let numbers2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    let removePeople = (e)=>{
        setbids(bids.filter(function(person) {
            return person !== e
        }));
    }

    let addBid = ()=>{
        if (!isNaN(parseInt(openPana)) && !isNaN(parseInt(closePana))){
            if (parseInt(amount) >= localStorage.getItem('min_bet')){
                let panna;
                if (types === "0"){
                    panna = openPana.toString()+' - '+closePana.toString()
                } else {
                    panna = closePana.toString()+' - '+openPana.toString()
                }

                let bidObj = {amount: amount, number:panna, session: ""}
                setbids(current => [...current, bidObj]);
            } else if (parseInt(amount) < 10) {
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Bid Amount must be above "+localStorage.getItem("min_bet")+" INR"
                })
            } else if (!numbers.includes(parseInt(bid)) && !numbers.includes(bid)) {
                Swal.fire({
                    toast: true,
                    title: 'Oops...',
                    text: "Enter valid bid number"
                })
            }
        }
    }

    const [amount, setamount] = useState("");
    const [bid, setbid] = useState("");

    let loginSubmit = () =>{

        let numberString,amountString,typesString

        let numberArray = []
        let amountArray = []
        let typesArray = []

        bids.map((result) => {
            numberArray.push(result.number)
            amountArray.push(result.amount)
            typesArray.push(result.session)
        })

        numberString = numberArray.join(",")
        amountString = amountArray.join(",")
        typesString = typesArray.join(",")

        var form = new FormData();

        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        form.append("bazar", marketName);
        form.append("number", numberString);
        form.append("amount", amountString);
        form.append("types", typesString);
        form.append("game", game_name);

        axios
            .post(process.env.REACT_APP_URL+`/api2/bet.php`,form)
            .then((response) => {
                if (response.data.nosession){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: "Session Expired"
                    }).then((e)=>{
                        localStorage.clear()
                        window.location.href = "/login";
                    })
                }
                if(response.data.success === "0"){
                    Swal.fire({
                        toast: true,
                        title: 'Oops...',
                        text: response.data.msg
                    })
                } else {
                    navigate("/home")
                }
            })
    }


    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv">

                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span>{marketName}, {game_name}</span>
                            </div>
                        </div>


                        <div className="wallet-box upi_apps">
                            <span className="heading"  style={{color:'white',borderBottom:"solid 1px #fff"}}>₹ {localStorage.getItem("wallet")}</span>
                            <span className="subheading"  style={{color:'white'}}>Balance</span>
                        </div>


                        <div style={{padding:"5px"}}>
                            <div className="date-block">
                                <span style={{color:"#00839F"}}>
                                    {new Date().getDate() + '/'+new Date().getMonth()+1+ '/'+new Date().getFullYear()}
                                </span>
                            </div>
                        </div>

                        <div className="main-game-screen">

                            <input
                                className="edittext"
                                name="mobile"
                                type="number"
                                value={amount}
                                onChange={(e)=>{setamount(e.target.value)}}
                                placeholder="Enter Points"
                            />

                            <select className="edittext form-select" onChange={(e)=>{setopenPana(e.target.value)}}>
                                <option selected>Select Pana</option>
                                { numbers.map((result) => { return (
                                <option value={result}>{result}</option>
                                )})}
                            </select>

                            <select className="edittext form-select" onChange={(e)=>{setclosePana(e.target.value)}}>
                                <option selected>Select Digit</option>
                                { numbers2.map((result) => { return (
                                    <option value={result}>{result}</option>
                                )})}
                            </select>

                            <select className="edittext form-select" onChange={(e)=>{settype(e.target.value)}}>
                                <option selected>Select Type</option>
                                <option value="0">OPEN PANA CLOSE DIGIT</option>
                                <option value="1">OPEN DIGIT CLOSE PANA</option>
                            </select>

                            <button className="button" onClick={addBid}>
                                <b>ADD BID</b>
                            </button>

                            { bids.map((result) => { return (
                                <div className="bet-list" key={result.number}>
                                    <span>
                                        {result.number}
                                    </span>
                                    <span>
                                       {result.amount}
                                    </span>
                                    <span>
                                        {result.session} <img onClick={()=>{removePeople(result)}} src="../images/delete.png" />
                                    </span>
                                </div>
                            )})}

                            <button className="button" onClick={loginSubmit}>
                                <b>SUBMIT</b>
                            </button>

                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
