import React from "react";
import {Link} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            wallet: "",
            history:[],
            type:""
        }
    }

    componentDidMount() {

        const windowUrl = window.location.search;

        const query = new URLSearchParams(windowUrl);
        this.setState({
            type:query.get('type')
        })

        console.log(this.state.type)
        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));
        form.append("type", query.get('type'));

        axios
            .post(process.env.REACT_APP_URL+"/api2/winning_ledger.php", form)
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        wallet:response.data.wallet,
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {

        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">

                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>My Winnings</span>
                                </div>
                            </div>

                            <div style={{padding:"0px"}}>
                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        <div className="transaction_block" key={result.sn}>
                                            <div>
                                                <span className="">{result.remark}</span>
                                                <span>{result.date}</span>
                                            </div>
                                            <span>{result.amount}</span>
                                        </div>
                                    )})}
                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default Wallet;
