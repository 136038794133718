import {useCallback, useEffect, useState} from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';

const Home = (props) => {

    const [amount, setamount] = useState("");
    let data = useLocation();

    const [marketName, setmarketName] = useState( data.state[0].market);
    if (data.state == null){
        window.location.href = "/";
    }

    let is_open_status = data.state[0].is_open;
    // function back(){
    //     let navigate = useNavigate();
    //     navigate(-1)
    // }
    useEffect(() => {
        window.scrollTo({ top:0, left:0, behavior: "instant"})
    }, [])

    return (<>
            <div className="RootView">
                <div className="RootWidth">

                    <div className="loginDiv" style={{background:"#eee"}}>


                        <div className="toolbar">
                            <div>
                                <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                <span>{marketName}</span>
                            </div>


                        </div>

                        <div style={{ paddingBottom:"20px"}}>

                            <div className="games">
                                <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'single'}]}>
                                <div className="maingameblock">
                                    <div className="imgBlock">
                                       <img src="../images/ic_singledigits.png" />
                                    </div>
                                    <span>Single Digits</span>
                                </div>
                                </Link>

                                <Link to={"/mainBulk"} state={[{market: marketName, is_open: is_open_status, game: 'single'}]}>
                                <div className="maingameblock">
                                    <div className="imgBlock">
                                       <img src="../images/ic_singledigits.png" />
                                    </div>
                                    <span>Single Bulk Digits</span>
                                </div>
                                </Link>

                              
                            </div>

                                <div className="games">

                        <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/jodi.png" />
                                </div>
                                <span>Jodi</span>
                            </div>
                            </Link>

                            <Link to={"/jodiBulk"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/jodi.png" />
                                </div>
                                <span>Jodi Bulk</span>
                            </div>
                            </Link>
                                 
                               
                                </div>

                            <div className="games">

                            <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/single_pana.png" />
                                </div>
                                <span>Single Pana</span>
                            </div>
                            </Link>

                            <Link to={"/singlePanaBulk"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/single_pana.png" />
                                </div>
                                <span>Single Pana Bulk</span>
                            </div>
                            </Link>

                        
                               
                           
                            </div>

                            <div className="games">

                            <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/doublepana.png" />
                                </div>
                                <span>Double Pana</span>
                            </div>
                            </Link>

                            <Link to={"/singlePanaBulk"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/doublepana.png" />
                                </div>
                                <span>Double Pana Bulk</span>
                            </div>
                            </Link>


                            

                            </div>


                            <div className="games">

                            <Link to={"/order"} state={[{market: marketName, is_open: is_open_status, game: 'triplepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/triplepana.png" />
                                </div>
                                <span>Triple Pana</span>
                            </div>
                            </Link>

                            <Link to={"/panelGroup"} state={[{market: marketName, is_open: is_open_status, game: 'singlepana'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/panelgroup.png" />
                                </div>
                                <span>Panel Group</span>
                            </div>
                            </Link>




                            </div>

                            

                            <div className="games">

                            <Link to={"/spdptp"} state={[{market: marketName, is_open: is_open_status, game: 'triplepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/spdptp.png" />
                                </div>
                                <span>SP DP TP</span>
                            </div>
                            </Link>

                            <Link to={"/choicePana"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/choicepana.png" />
                                </div>
                                <span>Choice Pana SP DP</span>
                            </div>
                            </Link>


                            </div>

                            <div className="games">

                            <Link to={"/motor"} state={[{market: marketName, is_open: is_open_status, game: 'singlepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/spmotor.png" />
                                </div>
                                <span>SP Motor</span>
                            </div>
                            </Link>

                            <Link to={"/motor"} state={[{market: marketName, is_open: is_open_status, game: 'doublepanna'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/spdptp.png" />
                                </div>
                                <span>DP Motor</span>
                            </div>
                            </Link>


                            </div>

                            
                            <div className="games">

                            <Link to={"/oddeven"} state={[{market: marketName, is_open: is_open_status}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/jodi.png" />
                                </div>
                                <span>Odd Even</span>
                            </div>
                            </Link>

                            <Link to={"/twoDigitPanel"} state={[{market: marketName, is_open: is_open_status, game: 'Two Digit Panel'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/twodigit.png" />
                                </div>
                                <span>Two Digit Panel</span>
                            </div>
                            </Link>


                            </div>

                            
                            
                            <div className="games">

                            <Link to={"/groupJodi"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/group_jodi.png" />
                                </div>
                                <span>Group Jodi</span>
                            </div>
                            </Link>

                            <Link to={"/digitBasedJodi"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/digit_based_jodi.png" />
                                </div>
                                <span>Digit Based Jodi</span>
                            </div>
                            </Link>


                            </div>

                            
                            
                            <div className="games">

                            <Link to={"/redbracket"} state={[{market: marketName, is_open: is_open_status, game: 'jodi'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/red_bracket.png" />
                                </div>
                                <span>Red Bracket</span>
                            </div>
                            </Link>

                            <Link to={"/halfSangamA"} state={[{market: marketName, is_open: is_open_status, game: 'halfsangam'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/sangam.png" />
                                </div>
                                <span>Half Sangam Open</span>
                            </div>
                            </Link>


                            </div>

                            
                            
                            <div className="games">

                                
                            <Link to={"/halfSangamB"} state={[{market: marketName, is_open: is_open_status, game: 'halfsangam'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/sangam.png" />
                                </div>
                                <span>Half Sangam Close</span>
                            </div>
                            </Link>

                            <Link to={"/fullSangam"} state={[{market: marketName, is_open: is_open_status, game: 'fullsangam'}]}>
                            <div className="maingameblock">
                                <div className="imgBlock">
                                <img src="../images/sangam.png" />
                                </div>
                                <span>Full Sangam</span>
                            </div>
                            </Link>



                            </div>

                        



                        </div>

                    </div>


                </div>
            </div>


        </>
    );
};

export default Home;
