import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";
import styles from "../style/Login.module.css"


class WithdrawMoney extends React.Component {

    constructor() {
        super();
        this.state = {
            acNo:"",
            bank:"",
            name:"",
            branch:"",
            ifsc:"",
            paytm:"",
            gpay:"",
            phonepe:"",
            upi:"",
        }
    }

    componentDidMount() {
        

        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));

        axios
            .post(process.env.REACT_APP_URL+"/api2/get_bank_details.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    if (response.data.success == "1"){
                        this.setState({
                            acNo:response.data.acno,
                            bank:response.data.bank,
                            name:response.data.name,
                            branch:response.data.branch,
                            ifsc:response.data.ifsc,
                            paytm:response.data.paytm,
                            gpay:response.data.gpay,
                            phonepe:response.data.phonepe,
                            upi:response.data.upi,
                        })
                    }
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }

    


    render() {



        let apiCall = () =>{

            var form = new FormData();
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));

            form.append("acno", this.state.acNo,);
            form.append("bank", this.state.bank);
            form.append("name", this.state.name);
            form.append("branch", this.state.branch);
            form.append("ifsc", this.state.ifsc);
            form.append("paytm", this.state.paytm);
            form.append("gpay", this.state.gpay);
            form.append("phonepe", this.state.phonepe);
            form.append("upi", this.state.upi);

            axios
                .post(process.env.REACT_APP_URL+`/api2/withdraw_mode.php`,form)
                .then((response) => {
                    console.log(response)
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    if(response.data.success === "0"){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: response.data.msg,
                        })
                    } else {
                        Swal.fire({
                            toast: true,
                            title: 'Withdraw Request',
                            text: response.data.msg,
                        })
                    }
                })

        }

        return (<>
                <div className="RootView">
            <div className="RootWidth authBack" style={{background:"#eee"}}>

            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Bank Details</span>
                                </div>


                            </div>

                <div className={styles.loginDiv} 
                        >
                    
                    <img className="logoIcon" alt="" src="../images/tinedlogo.png" style={{width:"150px"}} />

                    <div
                        className={styles.frameForm}
                    >
                     

                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input
                                type="text"
                                value={this.state.name}
                                placeholder="Account Holder Name"
                                onChange={(e)=>{this.setState({name:e.target.value})}}
                            />
                        </div>

                        
                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input
                                type="number"
                                value={this.state.acNo}
                                placeholder="Account Number"
                                onChange={(e)=>{this.setState({acNo:e.target.value})}}
                            />
                        </div>

                        
                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input 
                                type="text"
                                value={this.state.bank}
                                placeholder="Bank Name"
                                onChange={(e)=>{this.setState({bank:e.target.value})}}
                            />
                        </div>

                        
                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input 
                                type="text"
                                value={this.state.branch}
                                placeholder="Branch Name"
                                onChange={(e)=>{this.setState({branch:e.target.value})}}
                            />
                        </div>

                        
                        
                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input 
                                type="text"
                                value={this.state.ifsc}
                                placeholder="IFSC"
                                onChange={(e)=>{this.setState({ifsc:e.target.value})}}
                            />
                        </div>


                        
                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input 
                                type="text"
                                value={this.state.paytm}
                                placeholder="Paytm Number"
                                onChange={(e)=>{this.setState({paytm:e.target.value})}}
                            />
                        </div>
                        
                        
                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input 
                                type="text"
                                value={this.state.gpay}
                                placeholder="Gpay Number"
                                onChange={(e)=>{this.setState({gpay:e.target.value})}}
                            />
                        </div>
                        

                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input 
                                type="text"
                                value={this.state.phonepe}
                                placeholder="PhonePe Number"
                                onChange={(e)=>{this.setState({phonepe:e.target.value})}}
                            />
                        </div>
                        

                        <div className="inputBlock" style={{background:"white", marginBottom:"10px"}}>
                            <img src="../images/profile_icon.png" />
                            <input 
                                type="text"
                                value={this.state.upi}
                                placeholder="UPI ID (VPA)"
                                onChange={(e)=>{this.setState({upi:e.target.value})}}
                            />
                        </div>

                        <button className="button" onClick={apiCall}>
                            <b>SAVE</b>
                        </button>


                    </div>

                </div>
            </div>
        </div>
            </>
        );
    }

}

export default WithdrawMoney;



