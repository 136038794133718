import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import Swal from "sweetalert2";



class HowTo extends React.Component {

    constructor() {
        super();
        this.state = {
            howto: ""
        }


    }

    componentDidMount() {


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));
        form.append("text", "howtoplay");

        axios
            .post(process.env.REACT_APP_URL+"/api2/content.php", form,
            )
            .then((response) => {
                if(response.status === 200){
                    if (response.data.nosession){
                        Swal.fire({
                            toast: true,
                            title: 'Oops...',
                            text: "Session Expired"
                        }).then((e)=>{
                            localStorage.clear()
                            window.location.href = "/login";
                        })
                    }
                    this.setState({
                        howto:response.data.howtoplay,
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {



        return (<>
                <div className="RootView">
                    <div className="RootWidth">

                        <div className="loginDiv">

                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Game Rules</span>
                                </div>
                            </div>


                            <div style={{padding:"15px"}}>
                                <span><div dangerouslySetInnerHTML={{__html: this.state.howto}} className='editor'></div></span>
                            </div>

                        </div>


                    </div>
                </div>


            </>
        );
    }

}

export default HowTo;
