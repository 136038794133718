import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../style/home.css';
import axios from "axios";
import { type } from "@testing-library/user-event/dist/type";


class Wallet extends React.Component {

    constructor() {
        super();
        this.state = {
            type: "",
            wallet: "",
            history:[],
            
            selectedDate: new Date().toISOString().slice(0, 10)
        }
    }

    componentDidMount() {

        const windowUrl = window.location.search;

        const query = new URLSearchParams(windowUrl);
        this.setState({
            type:query.get('type')
        })


        var form = new FormData();
        form.append("session", localStorage.getItem("token"));
        form.append("mobile", localStorage.getItem("mobile"));
        form.append("type", query.get('type'));
        form.append("date", this.state.selectedDate);


        axios
            .post(process.env.REACT_APP_URL+"/api2/games.php", form,
            )
            .then((response) => {
                console.log(response.data)
                if(response.status === 200){
                    localStorage.setItem("wallet", response.data.wallet)
                    this.setState({
                        history:response.data.data
                    })
                } else if(response.status === 401){
                    //  navigate("/login")
                }
            })
    }


    render() {

        const handleDateChange = (date) => {
            const formattedDate = new Date(date).toISOString().slice(0, 10);
            this.setState({
                selectedDate: formattedDate
            },
            () => {
                apiCall()
            });

        };

        const apiCall = ()=>{

            var form = new FormData();
            form.append("session", localStorage.getItem("token"));
            form.append("mobile", localStorage.getItem("mobile"));
            form.append("type",this.state.type);
            form.append("date", this.state.selectedDate);
    
    
            axios
                .post(process.env.REACT_APP_URL+"/api2/games.php", form,
                )
                .then((response) => {
                    console.log(response.data)
                    if(response.status === 200){
                        localStorage.setItem("wallet", response.data.wallet)
                        this.setState({
                            history:response.data.data
                        })
                    } else if(response.status === 401){
                        //  navigate("/login")
                    }
                })
                
        }


        return (<>
                <div className="RootView">
                    <div className="RootWidth" style={{background:"#eee"}}>

                        <div className="loginDiv">


                            <div className="toolbar">
                                <div>
                                    <img onClick={()=>{window.history.go(-1)}}  src="../images/back.png" style={{padding: "5px"}}/>
                                    <span>Bid History</span>
                                </div>


                            </div>

                            <div style={{padding:"15px"}}>

                                

                                <input className="datePick" type="date" onChange={(e) => handleDateChange(e.target.value)}
    value={this.state.selectedDate} />

                                { this.state.history && this.state.history.map((result) => {
                                    return (
                                        
                                        <div className="gameBox">

                                        
                                        <span  className="gameboxtitle">{result.bazar.replace("__"," ").replace("_"," ")}</span>

                                        <div className="transaction_block" style={{marginBottom:"10px"}} key={result.sn}>
                                            <div style={{width:"40%"}}>
                                                <span className="gameboxlable">Game Type</span>
                                                <span>{result.game.replace("_"," ")}</span>
                                            </div>
                                            <div style={{width:"25%"}}>
                                                <span className="gameboxlable">Digit</span>
                                                <span>{result.number}</span>
                                            </div>
                                            <div style={{width:"25%"}}>
                                                <span className="gameboxlable">Point</span>
                                                <span>{result.amount}</span>
                                            </div>
                                        </div>

                                        
                                        <span className="gameboxinfo">Transaction Time: {result.time}</span>

                                        
                                        <span className="gameboxinfo" style={{color:"var(--color-accent)"}}>{result.bid_status}</span>

                                        </div>
                                    )})}

                            </div>

                        </div>


                    </div>

                           
                </div>


            </>
        );
    }

}

export default Wallet;
